import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`With this option you can quickly add all trails that have a certain name,
belong to a certain user, are at a certain location or contain certain
keywords. Use the filters to narrow down the results.`}</p>
    <Image src="pro/collection-by-search.jpg" mdxType="Image" />
    <br />
    <br />
    <Link className="float-left" to="/pro/mapbyhand" mdxType="Link">
  ← Select activities by hand
    </Link>
    <Link className="float-right" to="/pro/mapevent" mdxType="Link">
  Create a time limited map for event →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      